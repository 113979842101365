import notifyError from '../../services/exception-notifier/exception-notifier'

const notifyWithContext = async (
  {
    error,
    message,
    response,
    cognitoMethod,
  }: {
    error?: unknown,
    message?: string,
    response?: unknown,
    cognitoMethod: string
  },
) => {
  notifyError(error ?? message, {
    message,
    cognitoMethod,
    ...(response ? { response } : {}),
  })
}

export default notifyWithContext
