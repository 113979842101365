import { ReactNode } from 'react'
import { confirmResetPassword } from 'aws-amplify/auth'
import Link from 'next/link'
import CommonPasswordForm from '../../common/PasswordForm/password-form'
import notifyWithContext from '../../../../utils/cognito/notify-with-context'

const generalError = (
  <div>
    We are unable to reset your password. Please try again.
    If this problem continues, please try later or{' '}
    <Link href="/contact-us" prefetch={false}>
      contact us.
    </Link>
  </div>
)

const PasswordForm = ({ onPasswordChanged }: { onPasswordChanged: () => void }) => {
  const submitPassword = async (password: string): Promise<ReactNode[]> => {
    const email = sessionStorage.getItem('email')
    const confirmationCode = sessionStorage.getItem('confirmationCode')

    if (email == null) {
      return ['Email is not set!']
    }
    if (confirmationCode == null) {
      return ['Confirmation code is not set!']
    }

    try {
      await confirmResetPassword({
        username: email,
        confirmationCode,
        newPassword: password,
      })

      sessionStorage.removeItem('email')
      sessionStorage.removeItem('confirmationCode')
      onPasswordChanged()
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'InvalidPasswordException':
            return ['Invalid password']
          case 'CodeMismatchException':
            return ['Invalid code']
          case 'LimitExceededException':
            return ['You have tried to reset your password too many times. Please wait 1 hour before trying again.']
          default:
            await notifyWithContext({
              message: 'An unexpected error from Cognito occurred when resetting the password. The method that caused this error is confirmResetPassword.',
              error,
              cognitoMethod: 'confirmResetPassword',
            })
            return [generalError]
        }
      }
      await notifyWithContext({
        message: 'An unexpected error from Cognito occurred when resetting the password. The method that caused this error is confirmResetPassword.',
        error,
        cognitoMethod: 'confirmResetPassword',
      })
      return [generalError]
    }
    return []
  }

  return (
    <CommonPasswordForm submitPassword={submitPassword} />
  )
}

export default PasswordForm
