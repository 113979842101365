import React, {
  ReactNode, useEffect, useRef, useState,
} from 'react'
import { Button, Callout, ErrorMessages } from '@sh24/ui-components'
import Link from 'next/link'
import styled from 'styled-components'

const SecondsContainer = styled.span`
  width: 30px;
  text-align: center;
`

const countdownMessage = (timeToWait: number) => (
  <>
    Please wait{' '}
    <SecondsContainer>{timeToWait}</SecondsContainer> second{timeToWait === 1 ? '' : 's'}{' '}
    before requesting a new verification code.
  </>
)

const ResendVerificationCode = (
  {
    codeReRequestTime,
    requestVerificationCode,
    calloutTimeout = 15,
    initialRequestTimeout = undefined,
  }: {
    codeReRequestTime: number,
    requestVerificationCode: () => Promise<ReactNode[]>,
    calloutTimeout?: number,
    initialRequestTimeout?: number,
  },
) => {
  const initialRequestTimeoutWithDefault = initialRequestTimeout ?? codeReRequestTime

  const requestInProgress = useRef(false)
  const requestedRecently = useRef(initialRequestTimeoutWithDefault > 0)
  const lastRequestTime = useRef<number>(Date.now() - (codeReRequestTime - initialRequestTimeoutWithDefault) * 1000)
  const [showPleaseWaitMessage, setShowPleaseWaitMessage] = useState(false)
  const [pleaseWaitSeconds, setPleaseWaitSeconds] = useState(0)
  const [showResentMessage, setShowResentMessage] = useState(false)
  const [showResendErrorMessage, setShowResendErrorMessage] = useState(false)
  const [resendErrors, setResendErrors] = useState<ReactNode[]>([])
  const intervalDelay = 100

  useEffect(() => {
    const timer = setInterval(() => {
      const timeElapsed = Date.now() - lastRequestTime.current

      const countdownSeconds = codeReRequestTime - Math.round(timeElapsed / 1000)
      setPleaseWaitSeconds(countdownSeconds)
      if (countdownSeconds <= 0) {
        requestedRecently.current = false
        setShowPleaseWaitMessage(false)
        setShowResendErrorMessage(false)
      }

      if (timeElapsed >= calloutTimeout * 1000) {
        setShowResentMessage(false)
      }
    }, intervalDelay)

    return () => clearInterval(timer)
  }, [])

  const resendVerificationCode = async () => {
    if (requestInProgress.current) {
      return
    }

    if (requestedRecently.current) {
      setShowPleaseWaitMessage(true)
      return
    }

    try {
      requestInProgress.current = true

      const verificationErrors = await requestVerificationCode()
      setResendErrors(verificationErrors ?? [])

      requestedRecently.current = true
      lastRequestTime.current = Date.now()
      setPleaseWaitSeconds(codeReRequestTime)

      if (verificationErrors.length === 0) {
        setShowResentMessage(true)
        setShowResendErrorMessage(false)
      } else {
        setShowResentMessage(false)
        setShowResendErrorMessage(true)
      }
    } finally {
      requestInProgress.current = false
    }
  }

  return (
    <>
      <p>
        Not received a verification code?
        Please wait {codeReRequestTime} seconds before requesting another code.
      </p>
      <div className="mb-sm mt-sm">
        <Button variation="secondary" text="Resend verification code" onClick={resendVerificationCode} />
      </div>
      {showResentMessage && (
        <Callout
          titleColour="/successBold"
          backgroundColour="/successFill"
          title="Verification code successfully re-sent"
          centered
        />
      )}
      {showPleaseWaitMessage
        && <ErrorMessages id="resend-wait" errors={[countdownMessage(pleaseWaitSeconds)]} />}
      {showResendErrorMessage
        && <ErrorMessages id="resend-errors" errors={[...resendErrors]} />}
      <p>
        If you do not get a verification code after resending,
        please <Link href="/contact-us" prefetch={false}>contact us.</Link>
      </p>
    </>
  )
}

export default ResendVerificationCode
