import React, { useEffect, useState, ReactNode } from 'react'
import styled from 'styled-components'
import {
  Button,
  ErrorMessages,
  Icon,
  TextField,
} from '@sh24/ui-components'
import useDebounce from '../../../../hooks/use-debounce'
import useThemeContext from '../../../../utils/use-theme-context'

const Container = styled.div`
  display: flex;
`

const ButtonContainer = styled.div`
  flex-grow: 1;
  text-align: right;
`

const PasswordCriteriaList = styled.ul`
  ${({ theme }) => `
    padding-inline-start: ${theme?.spacing?.xs};
  `}
`

const PasswordCriteriaListItem = styled.li`
  list-style-type: none;
`

const IconContainer = styled.div`
  display: inline-flex;
  vertical-align: text-bottom;
`

const validatePassword = (password: string): string[] => (
  [
    !(password.length >= 8) ? 'minLength' : null,
    !(/[0-9]/.test(password)) ? 'number' : null,
    !(/[^A-Za-z0-9]/.test(password)) ? 'specialCharacter' : null,
    !(/[A-Z]/.test(password)) ? 'upperCase' : null,
    !(/^[\S]+.*[\S]+$/.test(password)) ? 'whitespace' : null,
  ].filter((errorName): errorName is string => !!errorName)
)

const PasswordForm = (
  { submitPassword }:
    {
      submitPassword: (password: string) => Promise<ReactNode[]>,
    }) => {
  const theme = useThemeContext()
  const [password, setPassword] = useState('')
  const [passwordErrors, setPasswordErrors] = useState<ReactNode[]>([])
  const [passwordErrorsNames, setPasswordErrorsNames] = useState<string[]>([])
  const [repeatPassword, setRepeatPassword] = useState('')
  const [repeatPasswordErrors, setRepeatPasswordErrors] = useState<ReactNode[]>([])
  const [errors, setErrors] = useState<ReactNode[]>([])
  const [highlightFailedCriteria, setHighlightFailedCriteria] = useState<boolean>(false)

  const debouncedPassword = useDebounce(password, 500)
  const debouncedRepeatPassword = useDebounce(repeatPassword, 500)

  useEffect(() => {
    const validationErrorNames = validatePassword(debouncedPassword)
    setPasswordErrorsNames(validationErrorNames)
    if (validationErrorNames.length === 0) {
      setHighlightFailedCriteria(false)
      setPasswordErrors([])
    }
  }, [debouncedPassword])

  useEffect(() => {
    setRepeatPasswordErrors([])
  }, [debouncedRepeatPassword])

  const validateFormData = (submittedPassword: string, submittedRepeatPassword: string) => {
    const submittedPasswordErrors: string[] = []
    if (validatePassword(submittedPassword).length > 0) {
      submittedPasswordErrors.push("Your chosen password doesn't match all of the criteria.")
    }
    const submittedRepeatPasswordErrors = []

    if (submittedPassword !== submittedRepeatPassword) {
      submittedRepeatPasswordErrors.push('Repeated password must be the same as your chosen password.')
    }
    return [submittedPasswordErrors, submittedRepeatPasswordErrors]
  }

  const onSubmit = async (e : React.FormEvent) => {
    e.preventDefault()

    setPasswordErrors([])
    setRepeatPasswordErrors([])
    setErrors([])

    const validationErrors = validateFormData(password, repeatPassword)
    const [passwordValidationErrors, repeatPasswordValidationErrors] = validationErrors
    if (passwordValidationErrors.length > 0) {
      setPasswordErrors(passwordValidationErrors)
      setHighlightFailedCriteria(true)
    }
    if (repeatPasswordValidationErrors.length > 0) {
      setRepeatPasswordErrors(repeatPasswordValidationErrors)
    }
    if (passwordValidationErrors.length > 0 || repeatPasswordValidationErrors.length > 0) {
      return
    }

    setErrors(await submitPassword(password))
  }

  const displayIcon = (key: string, highlightRed: boolean) => {
    const isError = passwordErrorsNames.includes(key)

    const showErrorIcon = isError && highlightRed
    const errorColor = highlightRed ? theme?.palette?.error : theme?.palette?.black200

    return (
      <IconContainer className="mb-xs">
        <Icon
          name={showErrorIcon ? 'close-circle' : 'check-circle'}
          testId={showErrorIcon ? 'close-circle' : 'check-circle'}
          fill={isError ? errorColor : theme?.palette?.successFill}
          height={12}
          width={12}
          viewBox={showErrorIcon ? '-1 -1 23 23' : '0 0 24 24'}
          ariaHidden={false}
        />
      </IconContainer>
    )
  }

  return (
    <form onSubmit={onSubmit}>
      <h2 className="mb-md heading-3">Create your password</h2>
      <p className="mb-sm">
        Your password must contain:
      </p>
      <PasswordCriteriaList className="mb-lg">
        <PasswordCriteriaListItem>{displayIcon('minLength', highlightFailedCriteria)} 8 letters or more</PasswordCriteriaListItem>
        <PasswordCriteriaListItem>{displayIcon('number', highlightFailedCriteria)} At least 1 number</PasswordCriteriaListItem>
        <PasswordCriteriaListItem>{displayIcon('specialCharacter', highlightFailedCriteria)} At least 1 special character like $, %, & </PasswordCriteriaListItem>
        <PasswordCriteriaListItem>{displayIcon('upperCase', highlightFailedCriteria)} At least 1 upper-case letter</PasswordCriteriaListItem>
        <PasswordCriteriaListItem>{displayIcon('whitespace', highlightFailedCriteria)} Must not start nor end with whitespace</PasswordCriteriaListItem>
      </PasswordCriteriaList>
      <TextField
        id="password"
        type="password"
        label="Password"
        placeholder="Password"
        value={password}
        errors={passwordErrors}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
      />
      <TextField
        id="repeatPassword"
        type="password"
        label="Repeat password"
        placeholder="Repeat password"
        value={repeatPassword}
        errors={repeatPasswordErrors}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRepeatPassword(e.target.value)}
      />
      {errors.length > 0 && (<ErrorMessages id="error" errors={errors} />)}
      <Container>
        <ButtonContainer>
          <Button
            type="submit"
            text="Continue"
            iconName="arrow-right"
            animation="shiftRight"
          />
        </ButtonContainer>
      </Container>
    </form>
  )
}

export default PasswordForm
