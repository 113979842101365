import React, { useState, ReactNode } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import {
  Button,
  TextField,
  ErrorMessages,
} from '@sh24/ui-components'
import { isEmail } from '../../../../order-journeys/_v2/validation/validation-functions'

const Container = styled.div`
  display: flex;
`

const SubmitButtonContainer = styled.div`
  flex-grow: 1;
  text-align: right;
`

const EmailForm = (
  {
    heading,
    intro = null,
    submitEmail,
  }:
    {
      heading: ReactNode,
      intro?: ReactNode,
      submitEmail: (email: string) => Promise<ReactNode[]>
    }) => {
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState<ReactNode[]>([])
  const [generalErrors, setGeneralErrors] = useState<ReactNode[]>([])

  const validateFormData = (submittedEmail: string) => {
    const validationErrors = []

    if (!submittedEmail) {
      validationErrors.push('Email is required.')
    }
    if (!isEmail(submittedEmail)) {
      validationErrors.push('Please enter a valid email address.')
    }
    return validationErrors
  }

  const onSubmit = async (e : React.FormEvent) => {
    e.preventDefault()

    setEmail(email.trim())
    setErrors([])

    const validationErrors = validateFormData(email)
    if (validationErrors.length > 0) {
      setErrors(validationErrors)
      return
    }

    setGeneralErrors(await submitEmail(email))
  }

  return (
    <form onSubmit={onSubmit}>
      <h2 className="mb-md heading-3">
        {heading}
      </h2>
      {intro && intro}
      <TextField
        id="email"
        type="email"
        label="Email address"
        placeholder="Email address"
        value={email}
        errors={errors}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
      />
      {generalErrors && (<ErrorMessages id="errors" errors={generalErrors} />)}
      <Container>
        <div>
          <Link href="/account/login" prefetch={false}>
            Log in to your account
          </Link>
        </div>
        <SubmitButtonContainer>
          <Button
            type="submit"
            text="Continue"
            iconName="arrow-right"
            animation="shiftRight"
          />
        </SubmitButtonContainer>
      </Container>
    </form>
  )
}

export default EmailForm
