import { ReactNode, useState } from 'react'
import {
  Button, TextField,
} from '@sh24/ui-components'
import ResendVerificationCodePanel from '../ResendVerificationCode/resend-verification-code'

const VerifyEmailForm = (
  {
    email = null,
    requestVerification,
    submitVerification,
    codeReRequestTime = 60,
  }: {
    email?: string | null,
    requestVerification: () => Promise<ReactNode[]>,
    submitVerification: (confirmationCode: string) => Promise<ReactNode[]>,
    codeReRequestTime?: number,
  },
) => {
  const [emailCode, setEmailCode] = useState('')
  const [codeErrors, setCodeErrors] = useState<ReactNode[]>([])

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    setCodeErrors([])

    if (emailCode === '') {
      setCodeErrors(['Please enter a valid verification code.'])
      return
    }

    setCodeErrors(await submitVerification(emailCode))
  }

  return (
    <form onSubmit={onSubmit}>
      <h2 className="mb-md heading-3">We&apos;ve emailed a verification code to {email}</h2>
      <TextField
        id="verificationCode"
        type="number"
        label="Enter your verification code"
        placeholder="Enter your verification code"
        value={emailCode}
        errors={codeErrors}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailCode(e.target.value)}
      />
      <ResendVerificationCodePanel
        codeReRequestTime={codeReRequestTime}
        requestVerificationCode={requestVerification}
      />
      <Button
        type="submit"
        text="Continue"
        iconName="arrow-right"
        animation="shiftRight"
      />
    </form>
  )
}

export default VerifyEmailForm
