import { useEffect, useState, ReactNode } from 'react'
import { confirmResetPassword, resetPassword } from 'aws-amplify/auth'
import Link from 'next/link'
import CommonVerifyEmailForm from '../../common/VerifyEmailForm/verify-email-form'
import notifyWithContext from '../../../../utils/cognito/notify-with-context'

const generalError = (
  <div>
    We are unable to reset your password. Please try again.
    If this problem continues, please try later or{' '}
    <Link href="/contact-us" prefetch={false}>
      contact us.
    </Link>
  </div>
)

const generalResendError = (
  <div>
    We are unable to send you a verification code. Please try again.
    If this problem continues, please try later or{' '}
    <Link href="/contact-us" prefetch={false}>
      contact us.
    </Link>
  </div>
)

const VerifyEmailForm = (
  {
    onSuccessfulSubmit,
    codeReRequestTime = undefined,
  }: {
    onSuccessfulSubmit: () => void,
    codeReRequestTime?: number,
  }) => {
  const [email, setEmail] = useState<string | null>(null)

  useEffect(() => {
    setEmail(sessionStorage.getItem('email'))
  }, [])

  const submitVerification = async (confirmationCode: string): Promise<ReactNode[]> => {
    if (email == null) {
      return ['Email is not set!']
    }

    try {
      await confirmResetPassword({
        username: email,
        confirmationCode,
        newPassword: 'xxx',
      })
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'InvalidPasswordException':
          // This is expected!
            sessionStorage.setItem('confirmationCode', confirmationCode)
            onSuccessfulSubmit()
            break
          case 'CodeMismatchException':
          case 'NotAuthorizedException':
          case 'EmptyConfirmResetPasswordConfirmationCode':
            return ['Please enter a valid verification code.']
          case 'ExpiredCodeException':
            return ['Your code has expired. Please request a new verification code.']
          case 'LimitExceededException':
            return ['You have submitted too many verification codes. Please wait 1 hour before submitting another code.']
          default:
            await notifyWithContext({
              message: 'An unexpected error from Cognito when resetting the password. The method that caused this error is confirmResetPassword.',
              error,
              cognitoMethod: 'confirmResetPassword',
            })
            return [generalError]
        }
      }
      await notifyWithContext({
        message: 'An unexpected error from Cognito when resetting the password. The method that caused this error is confirmResetPassword.',
        error,
        cognitoMethod: 'confirmResetPassword',
      })
      return [generalError]
    }
    return []
  }

  const requestVerification = async (): Promise<ReactNode[]> => {
    if (email == null) {
      return ['Email is not set!']
    }

    try {
      const result = await resetPassword({ username: email })
      if (result.nextStep.resetPasswordStep !== 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
        await notifyWithContext({
          message: "Recieved an unexpected value for result.nextStep.resetPassword step when requesting a new code using the resetPassword method. Expected the value to be 'CONFIRM_RESET_PASSWORD_WITH_CODE'. The method that caused this response is resetPassword.",
          error: new Error('Unexpected step', { cause: { nextStep: result.nextStep } }),
          cognitoMethod: 'resetPassword',
        })
        return ['An error occurred']
      }
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'LimitExceededException':
            return ['You have requested too many verification codes. Please wait 1 hour before requesting another code.']
          default:
            await notifyWithContext({
              message: 'An unexpected error from Cognito occured when requesting a new code. The method that caused this error is resetPassword.',
              error,
              cognitoMethod: 'resetPassword',
            })
            return [generalResendError]
        }
      }
      await notifyWithContext({
        message: 'An unexpected error from Cognito when requesting a new code. The method that caused this error is resetPassword.',
        error,
        cognitoMethod: 'resetPassword',
      })
      return [generalResendError]
    }
    return []
  }

  return (
    <CommonVerifyEmailForm
      email={email}
      submitVerification={submitVerification}
      requestVerification={requestVerification}
      codeReRequestTime={codeReRequestTime}
    />
  )
}

export default VerifyEmailForm
